<template>
  <div class="filter-menu-dropdown card">
    <header class="card-header">
      <p class="card-header-title has-text-info">
        <span class="icon">
          <i class="fal fa-tachometer-alt" />
        </span>
        <span>Vehicle mileage</span>
      </p>
      <a class="card-header-icon" aria-label="close" @click="clearMenu">
        Close
      </a>
    </header>
    <hr class="hr is-marginless" />
    <div class="card-content">
      <div class="columns is-centered is-multiline">
        <div class="column is-12">
          <p class="is-size-4">{{ formattedMileage }}</p>
          <p class="is-size-7">Drag the sliders to choose a range</p>
        </div>
        <div class="column is-11 has-text-centered">
          <vue-slider
            class="slider"
            dot-size="33"
            :min="0"
            :max="500000"
            :interval="1000"
            :enable-cross="false"
            v-model="value"
            :disabled="loading"
          />
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <div class="column is-12">
        <div class="columns">
          <div class="column">
            <a class="has-text-danger" v-if="hasSelected" @click="clearFilter"
              >Reset</a
            >
            <label class="has-text-grey-light" v-else>Reset</label>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { debounce } from 'lodash'
export default {
  name: 'SimilarListingsMileageFilter',
  components: {
    VueSlider
  },
  data: () => ({
    value: [0, 0],
    initialValue: null
  }),
  watch: {
    value: 'setMileage'
  },
  mounted() {
    this.value = this.filter.length ? this.filter : [0, 0]
    this.initialValue = this.value
  },
  computed: {
    ...mapGetters({
      loading: 'companion/similarListings/loading',
      filter: 'companion/similarListings/filterBy/mileageFilter'
    }),
    hasSelected() {
      const isNotZero = value => value > 0
      return this.value && this.value.some(isNotZero)
    },
    formattedMileage() {
      const { value } = this
      let minMilage = value[0].toLocaleString('en').split(',')[0]
      let maxMilage = value[1].toLocaleString('en').split(',')[0]
      return this.hasSelected ? `${minMilage}-${maxMilage}k miles` : '0 miles'
    }
  },
  methods: {
    clearFilter() {
      this.value = [0, 0]
    },
    setMileage: debounce(async function() {
      const { value, initialValue } = this
      if (value !== initialValue) this.setFilter({ mileage: value })
      this.initialValue = []
    }, 500),
    ...mapMutations({
      clearMenu: 'companion/similarListings/filterBy/clearMenu'
    }),
    ...mapActions({
      setFilter: 'companion/similarListings/filterBy/updateFilter'
    })
  }
}
</script>
